import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

function About() {
  return (
    <div>
      <section className="container about" id="about">
            <div className="main-title">
                <h2>About <span>me</span><span className="bg-text">my stats</span></h2>
            </div>
            <div className="about-container">
                <div className="left-about">
                    <h4>Information About me</h4>
                    <p>
                        Introducing myself, I am a driven individual with a passion for technology and a relentless desire to learn. From a young age, I have been fascinated by computers and their potential.<br/><br/>Now, as an adult, I am constantly seeking new challenges and opportunities to grow in the world of programming and technology. With a focus on innovation and continuous improvement, I strive to make a positive impact in this ever-evolving industry.
                    </p>
                    <div className="btn-con">
                        <a href="files/cv.pdf" className="main-btn">
                            <span className="btn-text">Download CV</span>
                            <span className="btn-icon"><FontAwesomeIcon icon={faDownload} /></span>
                        </a>
                    </div>
                </div>
                <div className="right-about">
                    <div className="about-item">
                        <div className="abt-text">
                            <p className="large-text">50+</p>
                            <p className="small-text">Projects <br /> Completed</p>
                        </div>
                    </div>
                    <div className="about-item">
                        <div className="abt-text">
                            <p className="large-text">6+</p>
                            <p className="small-text">Years of <br /> experience</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-stats">
                <h4 className="stat-title">My Skills</h4>
                <div className="progress-bars">
                    <div className="progress-bar">
                        <p className="prog-title">HTML5</p>
                        <div className="progress-con">
                            <p className="prog-text">100%</p>
                            <div className="progress">
                                <span className="p100"></span>
                            </div>
                        </div>
                    </div>
                    <div className="progress-bar">
                        <p className="prog-title">CSS3</p>
                        <div className="progress-con">
                            <p className="prog-text">100%</p>
                            <div className="progress">
                                <span className="p100"></span>
                            </div>
                        </div>
                    </div>
                    <div className="progress-bar">
                        <p className="prog-title">Javascript</p>
                        <div className="progress-con">
                            <p className="prog-text">100%</p>
                            <div className="progress">
                                <span className="p100"></span>
                            </div>
                        </div>
                    </div>
                    <div className="progress-bar">
                        <p className="prog-title">ReactJS</p>
                        <div className="progress-con">
                            <p className="prog-text">60%</p>
                            <div className="progress">
                                <span className="p60"></span>
                            </div>
                        </div>
                    </div>
                    <div className="progress-bar">
                        <p className="prog-title">PHP</p>
                        <div className="progress-con">
                            <p className="prog-text">100%</p>
                            <div className="progress">
                                <span className="p100"></span>
                            </div>
                        </div>
                    </div>
                    <div className="progress-bar">
                        <p className="prog-title">NET</p>
                        <div className="progress-con">
                            <p className="prog-text">90%</p>
                            <div className="progress">
                                <span className="p90"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h4 className="stat-title">My Timeline</h4>
            <div className="timeline">
                <div className="timeline-item">
                    <div className="tl-icon">
                        <i className="fas fa-briefcase"></i>
                    </div>
                    <p className="tl-duration">2018 - present</p>
                    <h5>Fullstack web developer<span> - Onvia</span></h5>
                    <p>
                        Update/Develop the web pages made with wordpress, laravel or .NET of the clients
                    </p>
                </div>
                <div className="timeline-item">
                    <div className="tl-icon">
                        <i className="fas fa-briefcase"></i>
                    </div>
                    <p className="tl-duration">2010</p>
                    <h5>Frontend & Backend Developer<span> - Publidaser</span></h5>
                    <p>
                        Administration of corporate websites and update the websites
                    </p>
                </div>
            </div>
        </section>
    </div>
  );
}

export default About;
