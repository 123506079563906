import './App.scss';
import Header from './Components/Header';
import Sidebar from './Components/Sidebar';
import About from './Components/About';
import Projects from './Components/Projects';
//import Blog from './Components/Blog';
//import Contact from './Components/Contact';
import { Component } from 'react';

class App extends Component {
  render() {
    return <div className='app-wrapper'>
      <Header/>
      <main>
        <About/>
        <Projects/>
      </main>
      <Sidebar/>
    </div>
  }
  componentDidMount() {
    [...document.querySelectorAll(".control")].forEach(button => {
        button.addEventListener("click", function() {
            document.querySelector(".active-btn").classList.remove("active-btn");
            this.classList.add("active-btn");
            document.querySelector(".active").classList.remove("active");
            document.getElementById(button.dataset.id).classList.add("active");
        })
    });
  }
}

export default App;
