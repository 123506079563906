import { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdjust, faBriefcase, faEnvelope, faHome, faNewspaper, faUser } from '@fortawesome/free-solid-svg-icons'

class Sidebar extends Component {
  handleClick() {
    document.body.classList.toggle("light-mode");
  }
  render() {
    return <div>
      <div className="controls">
        <div className="control active-btn" data-id="home" >
          <FontAwesomeIcon icon={faHome} />
        </div>
        <div className="control" data-id="about">
          <FontAwesomeIcon icon={faUser} />
        </div>
        <div className="control" data-id="portfolio">
          <FontAwesomeIcon icon={faBriefcase} />
        </div>
        {
        /*<div className="control" data-id="blogs">
          <FontAwesomeIcon icon={faNewspaper} />
        </div>
        <div className="control" data-id="contact">
          <FontAwesomeIcon icon={faEnvelope} />
        </div>*/
        }
      </div>
      <div className="theme-btn" onClick={this.handleClick}>
          <FontAwesomeIcon icon={faAdjust} />
      </div>
    </div>
  };
}

export default Sidebar;