import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

function Header() {
  return (
    <header className="container header active" id="home">
        <div className="header-content">
            <div className="left-header">
                <div className="h-shape"></div>
                <div className="image">
                    <img src="img/hero.webp" alt=""/>
                </div>
            </div>
            <div className="right-header">
                <h1 className="name">
                    Hi, I'm <span>Cristian Ocaña Soler</span>&nbsp;
                    A Fullstack Developer.
                </h1>
                <p>
                    Since I was little, I've dreamed of having a computer to learn how to use it and make the most of it. As I grew up, my passion for technology and programming intensified, always seeking new ways to improve and enhance my skills.
                </p>
                <p>
                    I strive to write clean and scalable code, and automate the deployment process. My dedication and enthusiasm for technology drive me to keep learning and growing in this exciting field.
                </p>
                <div className="btn-con">
                    <a href="files/cv.pdf" className="main-btn">
                        <span className="btn-text">Download CV</span>
                        <span className="btn-icon"><FontAwesomeIcon icon={faDownload} /></span>
                    </a>
                </div>
            </div>
        </div>
    </header>
  );
}

export default Header;
