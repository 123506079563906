import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBehance, faGithub, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';

function Projects() {
  return (
    <section class="container" id="portfolio">
            <div class="main-title">
                <h2>My <span>Portfolio</span><span class="bg-text">My Work</span></h2>
            </div>
            <p class="port-text">
                Here is some of my work that I've done in various programming languages.
            </p>
            <div class="portfolios">
                <div class="portfolio-item">
                    <div class="image">
                        <img src="https://conquerloader.com/wp-content/uploads/2020/09/image.png" alt="" style={{objectFit: 'contain'}}/>
                    </div>
                    <div class="hover-items">
                        <h3>ConquerLoader</h3>
                        <div class="icons">
                            <a href="https://github.com/darkfoxdeveloper/ConquerLoader" class="icon" target="_blank">
                                <FontAwesomeIcon icon={faGithub} />
                            </a>
                            <a href="https://conquerloader.com" class="icon" target="_blank">
                                <FontAwesomeIcon icon={faExternalLink} />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="portfolio-item">
                    <div class="image">
                        <img src="https://i.postimg.cc/gJNFsMKB/ClientTR.png" alt=""/>
                    </div>
                    <div class="hover-items">
                        <h3 style={{ fontSize: '16px', textAlign: 'center' }}>TrinityConquer (Conquer Online Server) (Private Repository)</h3>
                        <div class="icons">
                            <a href="https://www.darkfoxdeveloper.com/d/434-trinityconquer-source-v5693" class="icon" target="_blank">
                                <FontAwesomeIcon icon={faExternalLink} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  );
}

export default Projects;
